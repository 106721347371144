import * as Sentry from '@sentry/browser';
import storeExport from '../../../clientStore';
import {
  makeCartAPIObject,
  makeCartApiObjectV3,
  setCartDependenciesInStore,
} from '../../../utilities';
import { addToCartApi } from '../cartApis';
import { setCart } from '../../../actions/setCart';
import { addToCartGtmEvent, luxuryAddToCartGtmEvent } from '../../../gtm/gtmServices';
import { priceCalculator, priceCalculatorV3 } from '../../cartServices';
import { setCoupon } from '../../../actions/setCoupon';
import { cartPricingApiV3 } from '../cartPricingApiV3';
import {
  cartManageAddToCartV4,
  cartManageApplyOrRemoveCoupon, cartManageChangeCarV4, cartManageChangeCityV4, cartManageRemoveCartV4, goAppMoneyApplyOrRemove
} from '../cartBreakupApis/cartBreakupApis';

export const cartActions = {
  add: 'ADD',
  remove: 'REMOVE',
  changeQuantity: 'CHANGE_QUANTITY',
  applyCoupon: 'APPLY_COUPON',
  updateCity: 'UPDATE_CITY',
  updateCar: 'UPDATE_CAR',
  goAppMoney: 'GO_APP_MONEY',
  updateTyreSize: 'UPDATE_TYRE_SIZE'
};

export const cartManipulate = ({
  type, serviceToAdd, clearCartAndAdd, onApiInit, onApiSuccess, onApiFailure, onApiComplete, addOns,
  count,
}) => {
  // console.log(type);
  // if (!type || (type === cartActions.add || type === cartActions.remove)) return 'INVALID_TYPE';

  const appState = { ...storeExport.store.getState() };
  let finalCartToSend = {};
  const props = appState;

  const getIndexFromCart = () => {
    let serviceIdToRemove = null;
    if (serviceToAdd.id || (serviceToAdd.id === 0)) { serviceIdToRemove = serviceToAdd.id; }
    if (serviceToAdd.service_id || (serviceToAdd.service_id === 0)) { serviceIdToRemove = serviceToAdd.service_id; }

    return props.cart.reduce(
      (a, item, index) => (item.service_id === serviceIdToRemove ? index : a),
      null,
    );
  };
  const filterOnChildId = (childId) => appState.cart.filter(
    (service) => service.childId !== childId,
  );

  const filterOnParentId = (parentId) => appState.cart.filter(
    (service) => service.parent_id !== parentId,
  );


  const filterOnParentIdAndServiceId = (parentId, serviceId) => appState.cart.filter(
    (service) => !(service.service_id === serviceId && service.parent_id === parentId),
  );


  setCartDependenciesInStore({
    car: {
      ...props.cardetails,
      car_type: props.cardetails?.fuel,
      id: props.cardetails?.fuel.car_type_id,
    },
  });

  if (type === cartActions.add) {
    if (serviceToAdd.retail_service_type_id === 0 || serviceToAdd.retail_service_type_id === 13) {
      appState.cart = filterOnParentId(serviceToAdd.retail_service_type_id);
    }
    // Logics for schedules, ac services and full body dent paint
    if (serviceToAdd.sub_category_id === 1 || serviceToAdd.sub_category_id === 3) {
      appState.cart = filterOnChildId(serviceToAdd.sub_category_id);
      if (serviceToAdd.package_details) {
        const allSubServicesOfIncomingPackage = serviceToAdd.package_details.map((packageObj) => packageObj.id);
        appState.cart = appState.cart.filter(
          (cartItem) => !(allSubServicesOfIncomingPackage.includes(cartItem.service_id)),
        );
      }
    }

    if (serviceToAdd.retail_service_type_id === 16) {
      if (serviceToAdd.id === 2825) {
        appState.cart = filterOnParentId(serviceToAdd.retail_service_type_id);
      }
      if (serviceToAdd.id !== 2825) {
        appState.cart = filterOnParentIdAndServiceId(serviceToAdd.retail_service_type_id, 2825);
      }
    }


    if (!clearCartAndAdd) finalCartToSend = makeCartAPIObject([...appState.cart, serviceToAdd, ...addOns]);
    else if (clearCartAndAdd) finalCartToSend = makeCartAPIObject([serviceToAdd, ...addOns]);

    try {
      if (appState.cardetails?.brand?.is_luxury) {
        luxuryAddToCartGtmEvent(
          appState?.user?.id,
          window.location.pathname,
          serviceToAdd.name || '',
          serviceToAdd.id,
          serviceToAdd.parent_id,
          serviceToAdd.sub_category_id,
          appState?.isAmcUser,
          appState?.cardetails?.brand?.id,
          addOns.map((item) => item.name),
        );
      } else {
        addToCartGtmEvent({
          user_id: appState?.user?.id,
          fire_screen: '',
          item_name: serviceToAdd.name || '',
          item_id: serviceToAdd.id,
          category: serviceToAdd.parent_id,
          sub_category: serviceToAdd.sub_category_id,
          miles:appState?.isAmcUser || false,
          car_id: appState?.cardetails?.model?.id,
          value: priceCalculatorV3(serviceToAdd).applicablePrice,
          engine_oil: serviceToAdd.oil_brand || null,
          boosters: addOns.map((item) => item.name),
        });
      }
    } catch (e) {
      console.error(e);
    }
  }

  if (type === cartActions.remove) {
    const arr = [...props.cart];
    arr.splice(getIndexFromCart(), 1);
    finalCartToSend = makeCartAPIObject(arr);
  }

  if (type === cartActions.changeQuantity) {
    const arr = [...props.cart];
    arr[getIndexFromCart()].count = count;
    finalCartToSend = makeCartAPIObject(arr);
  }

  if (onApiInit) onApiInit();
  addToCartApi(finalCartToSend).subscribe(
    (res) => {
      if (!res.status && res.error) {
        if (res.error === 'Not valid for selected services !' && type === cartActions.remove) {
          storeExport.store.dispatch(setCart(
            [...finalCartToSend.services, ...finalCartToSend.purchase_items],
          ));
          storeExport.store.dispatch(setCoupon(''));
        }
        alert(res.error);
        return;
      }
      if (res.data && res.data.services) {
        if (onApiSuccess) onApiSuccess();
        storeExport.store.dispatch(setCart([...res.data.services, ...res.data.purchase_items]));
      } else {
        Sentry.captureException({ errorText: 'services not found in response of cart add.', ...res });
      }
      if (onApiComplete) onApiComplete();
      setCartDependenciesInStore(res.data);
    },
    (error) => {
      if (onApiFailure) onApiFailure();
      if (onApiComplete) onApiComplete();
      Sentry.captureException({ errorText: 'API failed for cart add.', ...error });
    },
  );
};


export const cartManipulateV3 = ({
  type, serviceToAdd, clearCartAndAdd, onApiInit, onApiSuccess, onApiFailure, onApiComplete, addOns,
  count, couponToApply, goAppMoneyToApply,
}) => {
  const appState = storeExport.store.getState();
  const { cart } = appState;


  // check if cart already contains the service
  let newCartToSend = [];
  let cartItems = (cart && cart.all_services) ? cart.all_services : [];

  if (type === cartActions.add) {
    const temp = {};
    if (serviceToAdd.is_count_service) {
      temp.count = 1;
    }
    let itemNewInCart = true;
    if (serviceToAdd.is_multi_select === 0) {
      cartItems = cartItems.filter((item) => item.sub_category_id !== serviceToAdd.sub_category_id);
    }
    newCartToSend = cartItems.map(
      (cartItem) => {
        if (cartItem.id === serviceToAdd.id) {
          itemNewInCart = false;
          return { ...serviceToAdd, ...temp };
        }
        return cartItem;
      },
    );
    if (addOns && addOns.length) {
      for (let i = 0; i < addOns.length; i++) {
        newCartToSend.push(addOns[i]);
      }
    }
    if (itemNewInCart) {
      newCartToSend.push({ ...serviceToAdd, ...temp });
    }
  }

  if (type === cartActions.changeQuantity) {
    newCartToSend = cartItems.map(
      (cartItem) => {
        if (cartItem.id === serviceToAdd.id) {
          return { ...serviceToAdd, count };
        }
        return cartItem;
      },
    );
  }
  if (type === cartActions.updateTyreSize) {
    newCartToSend = cartItems.map(
      (cartItem) => {
        if (cartItem.brand === serviceToAdd.brand) {
          return { ...serviceToAdd, count };
        }
        return cartItem;
      },
    );
  }

  if (type === cartActions.updateCity || type === cartActions.updateCar) {
    newCartToSend = cartItems.map(
      (cartItem) => cartItem,
    );
  }


  if (type === cartActions.remove) {
    newCartToSend = cartItems.filter(
      (cartItem) => cartItem.id !== serviceToAdd.id,
    );
  }

  let couponCode = null;
  if (type === cartActions.applyCoupon) {
    if (couponToApply !== undefined) {
      couponCode = couponToApply;
    }
    newCartToSend = cartItems;
  }

  let goMoney = null;
  if(type === cartActions.goAppMoney) {
    if(goAppMoneyToApply) {
      goMoney = goAppMoneyToApply;
    }
    newCartToSend = cartItems;
  }

  const cartPricingObject = makeCartApiObjectV3(newCartToSend, couponCode, goAppMoneyToApply);
  if (onApiInit) onApiInit();
  // console.log({ cartPricingObject });
  let apiObservable = null;
  if (type === cartActions.add || type === cartActions.changeQuantity || type === cartActions.updateTyreSize) {
    const {
      car_id, city_id, purchase_items, services,
    } = cartPricingObject;
    const { userSelectedCarId } = appState;
    const addOnsIds = (addOns || []).map((addon) => addon.id);
    apiObservable = cartManageAddToCartV4({
      car_id,
      city_id,
      purchase_items: purchase_items.filter(
        (item) => (item.id === serviceToAdd.id || addOnsIds.includes(item.id)),
      ),
      services: services.filter(
        (item) => (item.id === serviceToAdd.id || addOnsIds.includes(item.id)),
      ),
      subscription_used: false,
      is_new_cart: !([...purchase_items, ...services].length),
      user_car_id: userSelectedCarId,
    });
  }

  if (type === cartActions.remove) {
    apiObservable = cartManageRemoveCartV4({
      service_ids: [`${serviceToAdd.id}`],
    });
  }

  if (type === cartActions.applyCoupon) {
    apiObservable = cartManageApplyOrRemoveCoupon({
      coupon_code: couponCode || '',
      coupon_message: '',
      remove_coupon_discount: !(couponCode && couponCode.length),
    });
  }

  if(type === cartActions.goAppMoney) {
    apiObservable = goAppMoneyApplyOrRemove(goMoney);
  }

  if (type === cartActions.updateCar) {
    const { userSelectedCarId } = appState;
    const { city_id, car_id } = cartPricingObject;
    apiObservable = cartManageChangeCarV4({
      city_id,
      car_id,
      user_car_id: userSelectedCarId,
    });
  }

  if (type === cartActions.updateCity) {
    const {
      city_id,
      car_id,
    } = cartPricingObject;

    apiObservable = cartManageChangeCityV4({
      city_id,
      car_id
    });
  }


  if (apiObservable) {
    apiObservable.subscribe(
      (res) => {
        if (!res.status && res.error) {
          alert(res.error);
          return;
        }
        if(res.status && res.data?.all_services)
          storeExport.store.dispatch(setCart({...res?.data, all_services: [...(res?.data?.all_services || [])]}));
        if (res.data && res.data.all_services) {
          if (onApiSuccess) onApiSuccess(res);
          // storeExport.store.dispatch(setCart(res.data));
        } else {
          // cart removed message comes in message key of response
          if (res.message.toLowerCase().indexOf('cart removed') !== -1) {
            storeExport.store.dispatch(setCart([]));
          }
          Sentry.captureException({ errorText: 'services not found in response of cart add.', ...res });
        }
        if (onApiComplete) onApiComplete(res);
        try {
          if (type === cartActions.add) {            
            if (appState.cardetails?.brand?.is_luxury) {
              luxuryAddToCartGtmEvent(
                appState?.user?.id,
                window.location.pathname,
                serviceToAdd.name || '',
                serviceToAdd.id,
                serviceToAdd.parent_id,
                serviceToAdd.sub_category_id,
                appState?.isAmcUser,
                appState?.cardetails?.brand?.id,
                addOns.map((item) => item.name),
              )
            } else  {
              addToCartGtmEvent({
                user_id: appState?.user?.id,
                fire_screen: '',
                item_name: serviceToAdd.name || '',
                item_id: serviceToAdd.id,
                category: serviceToAdd.parent_id,
                sub_category: serviceToAdd.sub_category_id,
                miles: appState?.isAmcUser || false,
                car_id: appState?.cardetails?.model?.id,
                value: priceCalculatorV3(serviceToAdd).applicablePrice,
                engine_oil: serviceToAdd.oil_brand || null,
                boosters: addOns.map((item) => item.name),
              });
            }
          }
        } catch (e) {
          console.error(e);
        }
      },
      (error) => {
        if (onApiFailure) onApiFailure(error);
        if (onApiComplete) onApiComplete(error);
        Sentry.captureException({ errorText: 'API failed for cart add.', ...error });
      },
    );
  }
};


export const addToCartServiceV3 = (service) => {
  const appState = storeExport.store.getState();
  const { cart } = appState;

  let serviceToAdd = service;
  serviceToAdd = {
    ...service,
    price_details: null,
    package_details: null,
    price: null,
    strike_through_price: null,
    material_cost: null,
  };
};
